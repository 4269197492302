<template>
  <div class="relative h100">
    <img src="@/assets/img/icon967.png" class="w100 block" />
    <div class="absolute w100 tc downWrap">
      <a class="bgf block c6 downbtn" :href="downUrl" _target="_blank"
        >下载APP</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      downUrl: "",
    };
  },
  methods: {},
  mounted() {
    let userAgent = navigator.userAgent;
    let isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1; //g
    let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let downUrl = [
      //ios技师端
      "https://apps.apple.com/cn/app/id1533676456",
      //ios用户端
      "https://apps.apple.com/cn/app/id1533676322",
      //android技师端
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.technician",
      //android用户端
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.user",
    ];
    if (isAndroid) {
      // 安卓下载
      if (this.$route.query.isUserPad) {
        // 用户端
        this.downUrl = downUrl[3];
      } else {
        // 技师端
        this.downUrl = downUrl[2];
      }
    } else if (isIOS) {
      // ios下载
      if (this.$route.query.isUserPad) {
        // 用户端
        this.downUrl = downUrl[1];
      } else {
        // 技师端
        this.downUrl = downUrl[0];
      }
    }
  },
};
</script>
<style lang="less" scoped>
.downWrap {
  left: 0;
  bottom: 0;
  height: 20%;
  padding: 0 20px 40px;
  background: #f44644;
  .downbtn {
    padding: 10px;
    border-radius: 5px;
  }
}
</style>
